var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.trust ? _c('div', {
    staticClass: "col-md-12 form-group"
  }, [_c('label', {
    attrs: {
      "for": "Jenis Harta"
    }
  }, [_vm._v(_vm._s(_vm.$t("property-type2")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.$t("savings")))])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "bank_type"
    }
  }, [_vm._v(_vm._s(_vm.$t("account-type")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.$t("acc-shares")))])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "bank_branch"
    }
  }, [_vm._v(_vm._s(_vm.$t("org-name")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v("One Cent")])])]), _vm.one_cent_username ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "bank_account_no"
    }
  }, [_vm._v(_vm._s(_vm.$t("username")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.one_cent_username,
      expression: "one_cent_username"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "bank_account_no",
      "readonly": ![2].includes(_vm.trust.status)
    },
    domProps: {
      "value": _vm.one_cent_username
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.one_cent_username = $event.target.value;
      }
    }
  })])]) : _vm._e(), _vm.one_cent_wallet_name ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "bank_account_no"
    }
  }, [_vm._v(_vm._s(_vm.$t("wallet-name")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.one_cent_wallet_name,
      expression: "one_cent_wallet_name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "bank_account_no",
      "readonly": ![2].includes(_vm.trust.status)
    },
    domProps: {
      "value": _vm.one_cent_wallet_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.one_cent_wallet_name = $event.target.value;
      }
    }
  })])]) : _vm._e()]), _vm.trust ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-right"
  }, [_c('div', [![2].includes(_vm.trust.status) ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.updateDetails
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]) : _vm._e()])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }